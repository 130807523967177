"use client";
import { useEffect } from "react";
import { usePathname } from "next/navigation";

export default function BrevoTracker() {
  const pathname = usePathname();

  useEffect(() => {
    const executeBrevoTracker = () => {
      let email;
      const currentData =
        JSON.parse(localStorage.getItem("persist:root")) || {};

      //console.log("BrevoTracker executed");

      window.sib = {
        equeue: [],
        client_key: "rm8lwbv89ohfnjrt21r1fe0d",
      };

      if (currentData["userData"]) {
        email = currentData["userData"].email;
        window.sib.email_id = email;
        //console.log("BrevoTracker", email);
      }

      window.sendinblue = {};
      for (
        var j = ["track", "identify", "trackLink", "page"], i = 0;
        i < j.length;
        i++
      ) {
        (function (k) {
          window.sendinblue[k] = function () {
            var arg = Array.prototype.slice.call(arguments);
            (
              window.sib[k] ||
              function () {
                var t = {};
                t[k] = arg;
                window.sib.equeue.push(t);
              }
            )(arg[0], arg[1], arg[2], arg[3]);
          };
        })(j[i]);
      }

      var n = document.createElement("script"),
        i = document.getElementsByTagName("script")[0];
      n.type = "text/javascript";
      n.id = "sendinblue-js";
      n.async = true;
      n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key;
      i.parentNode.insertBefore(n, i);
      window.sendinblue.page();
    };

    executeBrevoTracker(); // Ejecutar el script inicialmente
  }, [pathname]); // Ejecutar el script cada vez que la ruta cambia

  // Función para capturar eventos personalizados
  const trackCustomEvent = (eventName, eventData) => {
    if (window.sendinblue && typeof window.sendinblue.track === "function") {
      window.sendinblue.track(eventName, eventData);
      console.log("Se ejecuto un evento" + eventName + eventData);
    } else {
      console.error("Brevo Tracker no está inicializado correctamente");
    }
  };

  // Exponer la función trackCustomEvent al objeto global window
  useEffect(() => {
    window.trackBrevoEvent = trackCustomEvent;
  }, []);

  return null;
}
